import React from "react";

import axios from "axios";
import Cookies from "js-cookie";

import {
  Grid,
  GridItem,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Heading,
  Box,
  Button,
  Text,
  Tbody,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  HStack,
  Skeleton,
  VStack,
  Tr,
  Th,
  Td,
  IconButton,
  Editable,
  Table,
  EditableInput,
  EditablePreview,
  Tag,
  Input,
  PinInput,
  PinInputField,
  Divider,
  Switch,
  Checkbox,
  Center,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import { Table as RTable } from "rsuite";

import {
  WarningIcon,
  InfoIcon,
  CheckIcon,
  AddIcon,
  RepeatIcon,
  DeleteIcon,
  SearchIcon,
  ArrowForwardIcon,
  LinkIcon,
  AttachmentIcon,
  DownloadIcon,
  ViewIcon,
  CalendarIcon,
} from "@chakra-ui/icons";

import { Step, Steps, useSteps } from "chakra-ui-steps";
import moment from "moment";
import { FixedSizeList as List } from "react-window";

const API_URL =
  process.env.NODE_ENV === "production" ? "" : "http://localhost:8000";

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = "Basic " + Cookies.get("token");
  return config;
});

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      files: [],
      ignored: [],
      ghost: {
        previous: "",
        current: "",
        products: [],
      },
      filteredIgnored: [],
      deactivated: [],
      filteredDeactivated: [],
      ignoredValue: "",
      ignoredDelimiter: "",
      deactivatedValue: "",
      deactivatedDelimiter: "",
      login: false,
      loading: false,
      invalidPassword: false,
      authenticated: false,
      product: {},
      filterInput: "",
      mongo: {},
      storage: {},
      showProductModal: false,
      filtering: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.fetchStats = this.fetchStats.bind(this);
    this.humanFileSize = this.humanFileSize.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (Cookies.get("token") === undefined) {
      this.setState({ login: true });
    } else {
      this.fetchData();
      this.fetchStats();
    }
  }

  searchProduct(code) {
    this.setState({ product: {} });
    var param = { code };
    axios
      .get(`${API_URL}/api/salesforce/search`, {
        params: param,
      })
      .then((response) => {
        if (
          response.data.records !== null &&
          response.data.records.length > 0
        ) {
          this.setState({ product: response.data.records[0] });
        }
      });
  }

  humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + " B";
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (
      Math.round(Math.abs(bytes) * r) / r >= thresh &&
      u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
  }
  fetchStats() {
    axios.get(`${API_URL}/api/mongo/stats`).then((response) => {
      if (response.data !== null && response.data !== undefined) {
        this.setState({ mongo: response.data });
      } else {
        this.setState({ mongo: {} });
      }
    });
    // axios.get(`${API_URL}/api/storage/stats`).then((response) => {
    //   if (response.data !== null && response.data !== undefined) {
    //     this.setState({ storage: response.data });
    //   } else {
    //     this.setState({ storage: {} });
    //   }
    // });
  }
  fetchData() {
    axios.get(`${API_URL}/api/files`).then((response) => {
      if (response.data !== null && response.data !== undefined) {
        this.setState({ files: response.data });
      } else {
        this.setState({ files: [] });
      }
    });
    axios.get(`${API_URL}/api/products`).then((response) => {
      if (response.data.deactivated !== null) {
        this.setState({ deactivated: response.data.deactivated });
      } else {
        this.setState({ deactivated: [] });
      }
      if (response.data.ignored !== null) {
        this.setState({ ignored: response.data.ignored });
      } else {
        this.setState({ ignored: [] });
      }
      if (response.data.ghost !== undefined) {
        this.setState({ ghost: response.data.ghost });
      }
    });
  }
  render() {
    var processed = 0;
    var failed = 0;
    var processedPercent = 100.0;
    if (!this.state.login) {
      this.state.files.forEach((file) => {
        if (file.status === "Processed") {
          processed += 1;
        }
        if (file.status === "Failed") {
          failed += 1;
        }
      });
      processedPercent = ((processed * 100) / this.state.files.length).toFixed(
        2
      );
    }
    return this.state.login ? (
      <div className="login">
        <HStack>
          <PinInput
            onComplete={(value) => {
              this.setState({ loading: true });
              axios
                .get(`${API_URL}/api/ping`, {
                  auth: {
                    username: "admin",
                    password: value,
                  },
                })
                .then((response) => {
                  this.refs.lastPin.blur();
                  Cookies.set("token", btoa("admin:" + value));
                  this.setState({
                    loading: false,
                    authenticated: true,
                    invalidPassword: false,
                  });
                  this.fetchData();
                  this.fetchStats();
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({ password: "", invalidPassword: true });
                  this.refs.lastPin.blur();
                  this.setState({ loading: false });
                });
            }}
            onChange={(value) => {
              this.setState({ password: value });
            }}
            value={this.state.password}
            isInvalid={this.state.invalidPassword}
            mask
            size="lg"
            otp
          >
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField />
            <PinInputField ref="lastPin" />
          </PinInput>
          <IconButton
            isLoading={this.state.loading}
            isDisabled={!this.state.authenticated}
            onClick={() => {
              this.setState({ login: false });
            }}
            size="lg"
            variant="outline"
            aria-label="Send email"
            icon={<ArrowForwardIcon />}
          />
        </HStack>
      </div>
    ) : (
      <div>
        <Modal
          onClose={() => {
            this.setState({ showProductModal: false });
          }}
          isOpen={this.state.showProductModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent minW="60%" minH="40vh">
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack mt="50px" mb="50px" spacing="50px">
                <Box w="50%">
                  <Table variant="simple">
                    <Tbody>
                      <div>
                        <div style={{ fontWeight: 700 }}>Product Name</div>
                        <div isNumeric>
                          {this.state.product.Name ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>Product Code</div>
                        <div isNumeric>
                          {this.state.product.ProductCode ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>Active</div>
                        <div isNumeric>
                          <Checkbox
                            isChecked={this.state.product.IsActive}
                          ></Checkbox>
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>Hidden</div>
                        <div isNumeric>
                          <Checkbox
                            isChecked={this.state.product.SBQQ__Hidden__c}
                          ></Checkbox>
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>
                          Block Pricing Field
                        </div>
                        <div isNumeric>
                          {this.state.product.SBQQ__BlockPricingField__c ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                    </Tbody>
                  </Table>
                </Box>
                <Box w="50%">
                  <Table variant="simple">
                    <Tbody>
                      <div>
                        <div style={{ fontWeight: 700 }}>Product Family</div>
                        <div isNumeric>
                          {this.state.product.Family ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>Product Currency</div>
                        <div isNumeric>
                          {this.state.product.CurrencyIsoCode ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>
                          Exclude From Opportunity
                        </div>
                        <div isNumeric>
                          <Checkbox
                            isChecked={
                              this.state.product.SBQQ__ExcludeFromOpportunity__c
                            }
                          ></Checkbox>
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>
                          Asset Amendment Behavior
                        </div>
                        <div isNumeric>
                          {this.state.product
                            .SBQQ__AssetAmendmentBehavior__c ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div style={{ fontWeight: 700 }}>Default Quantity</div>
                        <div isNumeric>
                          {this.state.product.SBQQ__DefaultQuantity__c ?? (
                            <Skeleton height="20px" />
                          )}
                        </div>
                      </div>
                    </Tbody>
                  </Table>
                </Box>
              </HStack>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
        <Box
          position="fixed"
          top={0}
          w={"100%"}
          zIndex={1000000}
          h={16}
          style={{ backgroundColor: "#00A1DF" }}
          px={4}
        >
          <Heading
            size="md"
            style={{
              float: "left",
              marginTop: 20,
              color: "white",
              marginLeft: 30,
            }}
          >
            Salesforce Panel
          </Heading>
          <Button
            onClick={() => {
              Cookies.remove("token");
              this.setState({ login: true });
            }}
            className="log-out"
            variant="outline"
          >
            Log Out
          </Button>
          <IconButton
            mr={5}
            onClick={() => {
              this.setState({ files: [], ignored: [], deactivated: [] }, () => {
                this.fetchData();
                this.fetchStats();
              });
            }}
            className="log-out"
            variant="outline"
            icon={<RepeatIcon />}
          />
        </Box>
        <div className="container">
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(6, 1fr)"
            gap={10}
            style={{ marginBottom: 80 }}
          >
            <GridItem colSpan={2}>
              <Heading
                as="h2"
                size="xl"
                style={{
                  marginBottom: "20px",
                  textAlign: "center",
                  color: "#2D3848",
                }}
              >
                MongoDB
              </Heading>
              <StatGroup className="card">
                <Stat>
                  <StatLabel>Documents</StatLabel>
                  <StatNumber>{this.state.mongo.documents ?? 0}</StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    23.11%
                  </StatHelpText>
                </Stat>

                <Stat>
                  <StatLabel>Total Size</StatLabel>
                  <StatNumber>
                    {this.humanFileSize(this.state.mongo.totalSize ?? 0, true)}
                  </StatNumber>
                  <StatHelpText>
                    <CheckIcon
                      style={{
                        marginRight: "4px",
                        marginBottom: "3px",
                        color: "#72CA93",
                      }}
                    />
                    {this.humanFileSize(
                      10737418240 - this.state.mongo.totalSize ?? 0,
                      true
                    )}{" "}
                    left
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Average Size</StatLabel>
                  <StatNumber>
                    {this.humanFileSize(
                      this.state.mongo.averageSize ?? 0,
                      true
                    )}
                  </StatNumber>
                  <StatHelpText>
                    <InfoIcon
                      style={{
                        marginRight: "4px",
                        marginBottom: "3px",
                      }}
                    />
                    1MB limit
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Response Time</StatLabel>
                  <StatNumber>
                    {this.state.mongo.responseTime ?? 0} ms
                  </StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    peak: 500ms
                  </StatHelpText>
                </Stat>
              </StatGroup>
            </GridItem>
            <GridItem colSpan={2}>
              <Heading
                as="h2"
                size="xl"
                style={{
                  marginBottom: "20px",
                  textAlign: "center",
                  color: "#2D3848",
                }}
              >
                Salesforce
              </Heading>
              <StatGroup className="card">
                <Stat>
                  <StatLabel>Status</StatLabel>
                  <StatNumber>Healthy</StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    version: 51.0
                  </StatHelpText>
                </Stat>

                <Stat>
                  <StatLabel>Errors</StatLabel>
                  <StatNumber>0</StatNumber>
                  <StatHelpText>
                    <WarningIcon
                      style={{
                        marginRight: "4px",
                        marginBottom: "3px",
                        color: "#E53E3E",
                      }}
                    />
                    view logs
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Ignored</StatLabel>
                  <StatNumber>{this.state.ignored.length ?? 0}</StatNumber>
                  <StatHelpText>
                    <InfoIcon
                      style={{
                        marginRight: "4px",
                        marginBottom: "3px",
                      }}
                    />
                    product codes
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Response Time</StatLabel>
                  <StatNumber>unknown</StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    limit: 5s
                  </StatHelpText>
                </Stat>
              </StatGroup>
            </GridItem>
            <GridItem colSpan={2}>
              <Heading
                as="h2"
                size="xl"
                style={{
                  marginBottom: "20px",
                  textAlign: "center",
                  color: "#2D3848",
                }}
              >
                Storage
              </Heading>
              <StatGroup className="card">
                <Stat>
                  <StatLabel>Driver</StatLabel>
                  <StatNumber>SFTP</StatNumber>
                  <StatHelpText>
                    <CheckIcon
                      style={{
                        marginRight: "4px",
                        marginBottom: "3px",
                        color: "#72CA93",
                      }}
                    />
                    version: 6.0
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Files</StatLabel>
                  <StatNumber>{this.state.files.length ?? 0}</StatNumber>
                  <StatHelpText>
                    <StatArrow type="increase" />
                    100.00%
                  </StatHelpText>
                </Stat>

                <Stat>
                  <StatLabel>Processed</StatLabel>
                  <StatNumber>{processed}</StatNumber>
                  <StatHelpText>
                    <StatArrow type="decrease" />
                    {processedPercent}%
                  </StatHelpText>
                </Stat>
                <Stat>
                  <StatLabel>Failed</StatLabel>
                  <StatNumber>{failed}</StatNumber>
                  <StatHelpText>
                    <WarningIcon
                      style={{
                        marginRight: "4px",
                        marginBottom: "3px",
                        color: "#E53E3E",
                      }}
                    />
                    view logs
                  </StatHelpText>
                </Stat>
              </StatGroup>
            </GridItem>
          </Grid>
          <div style={{ marginBottom: 150 }}>
            <Steps
              colorScheme={"blue"}
              state="loading" // error
              style={{ marginBottom: 30 }}
              activeStep={this.state.activeStep}
            >
              <Step
                label={"Watch for Changes"}
                key={"watch-for-changes-step"}
              ></Step>
              <Step label={"Fetch Products"} key={"fetch-products-step"}></Step>
              <Step label={"Validate Products"} key={"validate-step"}></Step>
              <Step
                label={"Upsert Products"}
                key={"upsert-products-step"}
              ></Step>
              <Step label={"Upsert Prices"} key={"upsert-prices-step"}></Step>
              <Step
                label={"Invalidate Cache"}
                key={"invalidate-cache-step"}
              ></Step>
            </Steps>
            <div style={{ float: "right", marginBottom: 50 }}>
              <text style={{ marginRight: 10, color: "#84878E" }}>
                Triggered: 2 hours ago
              </text>
              <IconButton
                disabled={false}
                colorScheme="red"
                aria-label="Call Segun"
                size="lg"
                icon={<RepeatIcon />}
              />
            </div>
          </div>
          <Divider mb={10} />
          {/* <Box style={{ marginBottom: 10 }} overflowY="auto" height="500px"> */}
          <RTable
            loading={this.state.files.length < 1}
            virtualized
            height={500}
            rowHeight={65}
            // maxHeight={"500px"}
            data={this.state.files}
          >
            <RTable.Column verticalAlign="middle" flexGrow={1} fixed>
              <RTable.HeaderCell>Uploaded</RTable.HeaderCell>
              <RTable.Cell dataKey="uploaded">
                {(row) => {
                  return moment(Date.parse(row.uploaded)).fromNow();
                }}
              </RTable.Cell>
            </RTable.Column>

            <RTable.Column verticalAlign="middle" flexGrow={1} fixed>
              <RTable.HeaderCell>Type</RTable.HeaderCell>
              <RTable.Cell dataKey="type" />
            </RTable.Column>
            <RTable.Column verticalAlign="middle" flexGrow={2} fixed>
              <RTable.HeaderCell>Name</RTable.HeaderCell>
              <RTable.Cell dataKey="fileTitle" />
            </RTable.Column>
            <RTable.Column
              verticalAlign="middle"
              flexGrow={1}
              align="center"
              fixed
            >
              <RTable.HeaderCell>Status</RTable.HeaderCell>
              <RTable.Cell dataKey="status">
                {(row) => {
                  return (
                    <Tooltip
                      hasArrow
                      isDisabled={row.error === ""}
                      label={row.error}
                    >
                      <Tag
                        color={
                          row.status === "Processed"
                            ? "#379E0E"
                            : row.status === "Failed"
                            ? "#CF1421"
                            : "#0B979B"
                        }
                        style={{
                          borderColor:
                            row.status === "Processed"
                              ? "#B7EA8F"
                              : row.status === "Failed"
                              ? "#FFA39E"
                              : "#87E8DE",
                          borderWidth: "1px",
                        }}
                        bg={
                          row.status === "Processed"
                            ? "#F6FFEC"
                            : row.status === "Failed"
                            ? "#FFF0EF"
                            : "#E6FFFB"
                        }
                      >
                        {row.status}
                      </Tag>
                    </Tooltip>
                  );
                }}
              </RTable.Cell>
            </RTable.Column>
            <RTable.Column
              verticalAlign="middle"
              flexGrow={1}
              align="right"
              fixed
            >
              <RTable.HeaderCell>Options</RTable.HeaderCell>
              <RTable.Cell dataKey="options">
                {(row) => {
                  return (
                    <div>
                      <Button
                        style={{ marginRight: 10 }}
                        colorScheme="red"
                        size="sm"
                        disabled={
                          row.type === "FUTURE" || row.type === "MANUAL"
                        }
                        variant="outline"
                        onClick={() => {
                          axios
                            .get(`${API_URL}/api/rerun/` + row.archiveTitle)
                            .then((response) => {
                              this.setState({ files: [] });
                              this.fetchData();
                            });
                        }}
                      >
                        Rerun
                      </Button>
                      <Button
                        mr={"5px"}
                        colorScheme="cyan"
                        disabled={
                          row.type === "FUTURE" || row.type === "MANUAL"
                        }
                        size="sm"
                        variant="outline"
                        onClick={() => {
                          // window.open(row.url, "_blank").focus();
                        }}
                      >
                        Download
                      </Button>
                    </div>
                  );
                }}
              </RTable.Cell>
            </RTable.Column>
          </RTable>

          <IconButton
            mt={"20px"}
            float="right"
            onClick={() => {}}
            aria-label="Upload file"
            icon={<DownloadIcon />}
          />
          <Center mt={"100px"}>
            {/* <HStack mb={"50px"}> */}
            <Box w="500px">
              <Input
                w="100%"
                value={this.state.filterInput}
                onChange={(event) => {
                  this.setState({ filterInput: event.target.value });
                  var ignored = [];
                  var deactivated = [];
                  this.state.ignored.forEach((i) => {
                    if (
                      i.name
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) !== -1 ||
                      i.code
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) !== -1
                    ) {
                      ignored.push(i);
                    }
                  });
                  this.state.deactivated.forEach((d) => {
                    if (
                      d.name
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) !== -1 ||
                      d.code
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) !== -1
                    ) {
                      deactivated.push(d);
                    }
                  });
                  this.setState({
                    filteredIgnored: ignored,
                    filteredDeactivated: deactivated,
                  });
                }}
                placeholder="Filter by Name or Product Code"
              />
            </Box>
          </Center>
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={20}
            style={{ marginBottom: 50 }}
          >
            <GridItem colSpan={2}>
              <Heading
                as="h2"
                size="l"
                style={{
                  marginBottom: "20px",
                  textAlign: "center",
                  color: "#2D3848",
                }}
              >
                Ignored Products
              </Heading>
              <Box
                marginBottom="20px"
                overflowY="auto"
                height="720px"
                maxHeight="720px"
              >
                <List
                  height={720}
                  itemCount={
                    this.state.filterInput.length > 0
                      ? this.state.filteredIgnored.length
                      : this.state.ignored.length
                  }
                  itemSize={65}
                  width={"100%"}
                >
                  {({ index, style }) => {
                    let element =
                      this.state.filterInput.length > 0
                        ? this.state.filteredIgnored[index]
                        : this.state.ignored[index];
                    return (
                      <HStack
                        bgColor={index % 2 ? "transparent" : "gray.100"}
                        spacing="20px"
                        style={style}
                      >
                        <Box w="10%"></Box>
                        <Box w="80%">
                          <Tooltip
                            hasArrow
                            label={
                              element.name.length > 0
                                ? element.name
                                : "NOT FOUND"
                            }
                          >
                            <Editable
                              className={
                                element.code === "" ? "empty-editable" : ""
                              }
                              onChange={(value) => {
                                var temp = this.state.ignored;
                                temp[index].code = value;
                                this.setState({ ignored: temp });
                              }}
                              textAlign="center"
                              value={element.code}
                              placeholder="XXXXXX-XXX"
                            >
                              <EditablePreview />
                              <EditableInput />
                            </Editable>
                          </Tooltip>
                        </Box>
                        <Box w="10%">
                          <HStack>
                            <IconButton
                              size="sm"
                              colorScheme="blue"
                              aria-label="View Record"
                              onClick={async () => {
                                await this.searchProduct(element.code);
                                this.setState({
                                  showProductModal: true,
                                });
                              }}
                              icon={<CalendarIcon />}
                            />
                            <IconButton
                              size="sm"
                              colorScheme="red"
                              aria-label="Delete Record"
                              icon={<DeleteIcon />}
                              onClick={() => {
                                axios.get(
                                  `${API_URL}/api/activate/${encodeURIComponent(
                                    element.code
                                  )}`
                                );
                                var temp = this.state.ignored;
                                temp.splice(index, 1);
                                this.setState({ ignored: temp });
                              }}
                            />
                          </HStack>
                        </Box>
                      </HStack>
                    );
                  }}
                </List>
              </Box>
              <HStack spacing="20px">
                <Box w="80%">
                  <Input
                    value={this.state.ignoredValue}
                    onChange={(event) => {
                      this.setState({ ignoredValue: event.target.value });
                    }}
                    placeholder="Product Numbers"
                  />
                </Box>
                <Box w="15%">
                  <Input
                    value={this.state.ignoredDelimiter}
                    onChange={(event) => {
                      this.setState({
                        ignoredDelimiter: event.target.value,
                      });
                    }}
                    placeholder="Delimiter"
                  />
                </Box>
                <Box w="5%">
                  <IconButton
                    style={{ float: "right" }}
                    colorScheme="blue"
                    aria-label="Add Product Number"
                    onClick={() => {
                      var value = [];
                      if (this.state.ignoredValue !== "") {
                        var spl = this.state.ignoredValue.split(
                          this.state.ignoredDelimiter !== ""
                            ? this.state.ignoredDelimiter
                            : " "
                        );
                        spl.map((v) => value.push({ name: "", code: v }));
                      }
                      this.setState({
                        ignored: [...this.state.ignored, ...value],
                        ignoredValue: "",
                      });
                    }}
                    icon={<AddIcon />}
                  />
                </Box>
              </HStack>
            </GridItem>
            <GridItem colSpan={2}>
              <Heading
                as="h2"
                size="l"
                style={{
                  marginBottom: "20px",
                  textAlign: "center",
                  color: "#2D3848",
                }}
              >
                Deactivated Products
              </Heading>
              <Box
                marginBottom="20px"
                overflowY="auto"
                height="720px"
                maxHeight="720px"
              >
                <List
                  height={720}
                  itemCount={
                    this.state.filterInput.length > 0
                      ? this.state.filteredDeactivated.length
                      : this.state.deactivated.length
                  }
                  itemSize={65}
                  width={"100%"}
                >
                  {({ index, style }) => {
                    let element =
                      this.state.filterInput.length > 0
                        ? this.state.filteredDeactivated[index]
                        : this.state.deactivated[index];
                    return (
                      <HStack
                        bgColor={index % 2 ? "transparent" : "gray.100"}
                        spacing="20px"
                        style={style}
                      >
                        <Box w="10%"></Box>
                        <Box w="80%">
                          <Tooltip
                            hasArrow
                            label={
                              element.name.length > 0
                                ? element.name
                                : "NOT FOUND"
                            }
                          >
                            <Editable
                              className={
                                element.code === "" ? "empty-editable" : ""
                              }
                              onChange={(value) => {
                                var temp = this.state.deactivated;
                                temp[index].code = value;
                                this.setState({ deactivated: temp });
                              }}
                              textAlign="center"
                              value={element.code}
                              placeholder="XXXXXX-XXX"
                            >
                              <EditablePreview />
                              <EditableInput />
                            </Editable>
                          </Tooltip>
                        </Box>
                        <Box w="10%">
                          <HStack>
                            <IconButton
                              size="sm"
                              colorScheme="blue"
                              aria-label="View Record"
                              onClick={async () => {
                                await this.searchProduct(element.code);
                                this.setState({
                                  showProductModal: true,
                                });
                              }}
                              icon={<CalendarIcon />}
                            />
                            <IconButton
                              size="sm"
                              colorScheme="red"
                              aria-label="Delete Record"
                              icon={<DeleteIcon />}
                              onClick={() => {
                                axios.get(
                                  `${API_URL}/api/activate/${encodeURIComponent(
                                    element.code
                                  )}`
                                );
                                var temp = this.state.deactivated;
                                temp.splice(index, 1);
                                this.setState({ deactivated: temp });
                              }}
                            />
                          </HStack>
                        </Box>
                      </HStack>
                    );
                  }}
                </List>
              </Box>
              <HStack spacing="20px">
                <Box w="80%">
                  <Input
                    value={this.state.deactivatedValue}
                    onChange={(event) => {
                      this.setState({ deactivatedValue: event.target.value });
                    }}
                    placeholder="Product Numbers"
                  />
                </Box>
                <Box w="15%">
                  <Input
                    value={this.state.deactivatedDelimiter}
                    onChange={(event) => {
                      this.setState({
                        deactivatedDelimiter: event.target.value,
                      });
                    }}
                    placeholder="Delimiter"
                  />
                </Box>
                <Box w="5%">
                  <IconButton
                    style={{ float: "right" }}
                    colorScheme="blue"
                    aria-label="Add Product Number"
                    onClick={() => {
                      var value = [];
                      if (this.state.deactivatedValue !== "") {
                        var spl = this.state.deactivatedValue.split(
                          this.state.deactivatedDelimiter !== ""
                            ? this.state.deactivatedDelimiter
                            : " "
                        );
                        spl.map((v) => value.push({ name: "", code: v }));
                      }
                      this.setState({
                        deactivated: [...this.state.deactivated, ...value],
                        deactivatedValue: "",
                      });
                    }}
                    icon={<AddIcon />}
                  />
                </Box>
              </HStack>
            </GridItem>
          </Grid>
          <Button
            style={{ float: "right" }}
            colorScheme="blue"
            isLoading={this.state.loading}
            onClick={() => {
              var ignored = [];
              var deactivated = [];
              this.state.ignored.map((i) => {
                ignored.push(i.code);
              });
              this.state.deactivated.map((d) => {
                deactivated.push(d.code);
              });
              this.setState({ loading: true });
              axios
                .put(`${API_URL}/api/products`, {
                  ignored: ignored,
                  deactivated: deactivated,
                })
                .then((response) => {
                  this.setState({ loading: false });
                  this.fetchData();
                });
            }}
          >
            Save
          </Button>
          <Tabs mt="100px" variant="enclosed">
            <TabList>
              <Tab>Lithuania</Tab>
              <Tab isDisabled color="gray.400">
                Ukraine
              </Tab>
              <Tab isDisabled color="gray.400">
                Belarus
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <p>Latest File: {this.state.ghost.current}</p>
                <p>Previous File: {this.state.ghost.previous}</p>
                <br />
                <List
                  height={720}
                  itemCount={
                    this.state.ghost.products !== null
                      ? this.state.ghost.products.length
                      : 0
                  }
                  itemSize={65}
                  width={"100%"}
                >
                  {({ index, style }) => {
                    let element = this.state.ghost.products[index];
                    return (
                      <HStack
                        bgColor={index % 2 ? "transparent" : "gray.100"}
                        spacing="20px"
                        style={style}
                      >
                        <Box w="10%"></Box>
                        <Box w="85%">
                          <Text textAlign="center">{element}</Text>
                        </Box>
                        <Box w="5%">
                          <HStack>
                            <IconButton
                              size="sm"
                              colorScheme="blue"
                              aria-label="View Record"
                              onClick={async () => {
                                await this.searchProduct(element);
                                this.setState({
                                  showProductModal: true,
                                });
                              }}
                              icon={<CalendarIcon />}
                            />
                          </HStack>
                        </Box>
                      </HStack>
                    );
                  }}
                </List>
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default App;
